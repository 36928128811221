<template>
    <Modal class="tiw__step__chooseDevice">
        <template #header>
            <h2>{{ useTranslate("tiw__chooseDevice__title") }}</h2>
            <p>{{ useTranslate("tiw__chooseDevice__subtitle") }}</p>
        </template>

        <div class="tiw__step__chooseDevice__body">
            <form name="chooseDeviceForm">
                <!-- Поле "IMEI" [решили пока что убрать] -->
                <!-- <Field v-if="0">
                    <template #title>
                        <h4>Введите IMEI вашего устройства</h4>
                    </template>
                    <template #body>
                        <div class="tiw_group">
                            <Input
                                type="text"
                                placeholder="IMEI"
                                name="imei"
                                maxlength="15"
                                inputmode="numeric"
                                v-model="ChooseDeviceStore.fields.imei"
                            />
                            <Button
                                type="button"
                                class="tiw__step__chooseDevice__btn-next"
                                :disabled="
                                    ChooseDeviceStore.fields.imei.length !== 15
                                "
                            >
                                <span>Продолжить</span>
                            </Button>
                        </div>
                    </template>
                    <template v-if="!howFindImeiBlock" #hintBottom>
                        <Button
                            color="link"
                            variant="link"
                            type="button"
                            @click="showInfoAboutImei"
                        >
                            <span>Как найти IMEI</span>
                        </Button>
                    </template>
                </Field> -->

                <!-- Инфо-блок с пояснением как узнать свой IMEI [решили пока что убрать] -->
                <!-- <template v-if="howFindImeiBlock">
                    <div class="tiw__step__chooseDevice__info">
                        <h3 class="tiw__title">
                            How do I find my IMEI number?
                        </h3>
                        <p class="tiw__text">
                            Возможный текстовый комментарий с пояснениями и
                            причинами
                        </p>
                        <ul class="tiw__ul tiw__ul_disc">
                            <li>
                                Dial <strong>*#06#</strong> to find the IMEI
                            </li>
                            <li>
                                Or, go to Settings > General > About to display
                                IMEI
                            </li>
                        </ul>
                        <figure class="tiw_tc">
                            <img
                                src="/img/iPhone-dial-imei.png"
                                alt="iPhone dial *#06#"
                            />
                        </figure>
                        <Button
                            color="link"
                            variant="link"
                            type="button"
                            class="tiw_fs-12"
                            @click="hideInfoAboutImei"
                        >
                            <span>Скрыть</span>
                        </Button>
                    </div>
                </template> -->

                <!-- Поле "Бренд" -->
                <Field class="tiw__field_brand">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__chooseDevice__brand") }}
                        </span>
                    </template>
                    <template #body>
                        <Select
                            v-model="ChooseDeviceStore.field_brand.value"
                            :options="ChooseDeviceStore.field_brand.data"
                            label="name"
                            name="brand"
                            :disabled="loadingToNext"
                        />
                    </template>
                </Field>

                <!-- Поле "Категория" -->
                <Field class="tiw__field_category">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__chooseDevice__category") }}
                        </span>
                    </template>
                    <template #body>
                        <Select
                            v-model="ChooseDeviceStore.field_category.value"
                            :options="ChooseDeviceStore.field_category.data"
                            name="category"
                            label="name"
                            :disabled="loadingToNext || disabledCategorySelect"
                        />
                    </template>
                </Field>

                <!-- Поле "Модель" -->
                <Field class="tiw__field_model">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__chooseDevice__model") }}
                        </span>
                    </template>
                    <template #body>
                        <Select
                            v-model="ChooseDeviceStore.field_model.value"
                            :options="ChooseDeviceStore.field_model.data"
                            name="model"
                            label="name"
                            :disabled="loadingToNext || disabledModelSelect"
                        />
                    </template>
                </Field>

                <!-- Поле "Память / Модель / Год" -->
                <Field class="tiw__field_other">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__chooseDevice__other") }}
                        </span>
                    </template>
                    <template #body>
                        <Select
                            v-model="ChooseDeviceStore.field_other.value"
                            :options="ChooseDeviceStore.field_other.data"
                            name="other"
                            label="variable"
                            :disabled="loadingToNext || disabledOtherSelect"
                        />
                    </template>
                </Field>

                <!-- Поле "Цвет" -->
                <Field class="tiw__field_color">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__chooseDevice__color") }}
                        </span>
                    </template>
                    <template #body>
                        <Select
                            v-model="ChooseDeviceStore.field_color.value"
                            :options="ChooseDeviceStore.field_color.data"
                            name="color"
                            label="color"
                            :disabled="loadingToNext || disabledColorSelect"
                        />
                    </template>
                </Field>
            </form>

            <div v-if="showDeviceNotAccepted" class="tiw__step__chooseDevice__deviceNotAccepted">
                <Notification type="danger">
                    {{ useTranslate("tiw__chooseDevice_deviceNotAccepted") }}
                </Notification>
            </div>

            <Button
                type="button"
                :disabled="
                    !ChooseDeviceStore.isSelectedManualFiles || loadingToNext
                "
                :loading="loadingToNext"
                class="tiw__step__chooseDevice__btn-next tiw__btn-next_manual"
                @click="clickToNext"
            >
                <span>{{ useTranslate("tiw__continue") }}</span>
            </Button>

            <p
                class="tiw__text tiw_fs-12 tiw_tc tiw_usn"
                v-html="useTranslate('tiw__chooseDevice__terms')"
            />
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "ChooseDevice",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [ChooseDevice]
 * Модальное окно / экран "Выбор устройства"
 */

// Data
import { I_AXIOS } from "@/hooks/requests/configRequest.model";

// Components
import Modal from "@/components/modal/modal.vue";
import Button from "@/ui/button/button.vue";
import Field from "@/ui/field/field.vue";
import Select from "@/ui/select/select.vue";
import Notification from "@/components/notification/notification.vue";

// Hooks
import { computed, ref, watch } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useRegisterProduct from "@/hooks/useRegisterProduct/useRegisterProduct";
import it4GetStatusQuestion from "@/hooks/requests/it4GetStatusQuestion/it4GetStatusQuestion";
import useTradeInCalculation from "@/hooks/useTradeInCalculation/useTradeInCalculation";
import { useAnalyticsEventPreState } from "@/hooks/useAnalytics/useAnalytics";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";

// Types
import type { I_it4GetStatusQuestion_Return } from "~/tiw/hooks/requests/it4GetStatusQuestion/it4GetStatusQuestion.types";
import { useBugTracker, BugTrackerSection } from "@/helpers/bugTracker";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();

const loadingToNext = ref<boolean>(false);
const showDeviceNotAccepted = ref<boolean>(false);

watch(ChooseDeviceStore.deviceChain, () => {
    showDeviceNotAccepted.value = false;
});

const clickToNext = async () => {
    loadingToNext.value = true;

    await useRegisterProduct();

    if (isNaN(parseFloat(HowYouRateDeviceStore.registeringDeviceData?.price))) {
        showDeviceNotAccepted.value = true;
        loadingToNext.value = false;

        // useBugTracker("ChooseDevice - next: price is NaN", BugTrackerSection.ERROR, {
        //     registeringDeviceData: HowYouRateDeviceStore.registeringDeviceData,
        // })

        return;
    }

    if (HowYouRateDeviceStore.registeringDeviceData.canWidgetGrading) {
        // Вопрос для экрана "В каком состоянии ваше устройство?"
        try {
            const StatusQuestionData = await it4GetStatusQuestion({
                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                token: TIWStore.WIDGET_DATA.token,
                lang: TIWStore.standardizedLocale,
                from: 3,
            });

            // useBugTracker("ChooseDevice - it4GetStatusQuestion (response)", BugTrackerSection.INFO, {
            //     response: StatusQuestionData,
            // });

            StatusQuestionData.options.forEach((el, ind) => {
                el.imageVariant = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2/${el.imgCid}`;
            });

            HowYouRateDeviceStore.questionData = StatusQuestionData;
        } catch (e) {
            useBugTracker("ChooseDevice - it4GetStatusQuestion", BugTrackerSection.ERROR, {
                exception: e.message,
                request: {
                    deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                    token: TIWStore.WIDGET_DATA.token,
                    lang: TIWStore.standardizedLocale,
                    from: 3,
                },
            });

            loadingToNext.value = false;
            return;
        }

        loadingToNext.value = false;
        TIWStore.switchStep("howYouRateDevice");
        useAnalyticsEventPreState();
    } else {
        HowYouRateDeviceStore.questionData = {
            question: useTranslate("tiw__calculatedDevice__title_pre"),
        } as I_it4GetStatusQuestion_Return["data"];
        HowYouRateDeviceStore.isBack = false;
        HowYouRateDeviceStore.grade = "Q"; // Оцениваем как по максимальному грейду
        HowYouRateDeviceStore.isNoPhotoSet = true;

        useTradeInCalculation();

        loadingToNext.value = false;

        TIWStore.switchStep("howYouRateDevice");
        useAnalyticsEventPreState();
    }
};

// const howFindImeiBlock = ref<boolean>(false);

// const showInfoAboutImei = () => (howFindImeiBlock.value = true);
// const hideInfoAboutImei = () => (howFindImeiBlock.value = false);

const disabledCategorySelect = computed<boolean>((): boolean => !ChooseDeviceStore.field_brand.value);
const disabledModelSelect = computed<boolean>((): boolean => !ChooseDeviceStore.field_category.value);
const disabledOtherSelect = computed<boolean>((): boolean => !ChooseDeviceStore.field_model.value);
const disabledColorSelect = computed<boolean>((): boolean => !ChooseDeviceStore.field_other.value);
</script>

<style lang="scss" src="./chooseDevice.scss"></style>
