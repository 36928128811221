import { useTIWStore } from "@/stores/tiw/tiw";

export const widgetScrollToTop = (position: number = 0, behavior: ScrollBehavior = "smooth"): void => {
    const TIWStore = useTIWStore();
    const root_element: string = TIWStore.INJECTED_TIW.current_settings.root_element;
    const wrapper: HTMLElement | null = document.querySelector(`.tiw[root_element="${root_element}"] > .tiw__wrapper`)

    if (!wrapper) return;

    wrapper.scrollTo({
        top: position,
        behavior: behavior,
    })
};
